<template>
  <!--密码弹窗-->
  <div class="pop-up" v-if="isShow">
    <div class="pop-container">
      <h3>Password</h3>
      <input class="password" type="password" placeholder="Password" v-model="password"  @keydown.enter="savePassword" ref="passwordInput">
      <p class="tips">{{tips}}</p>
      <div class="confirm-btn"  @click="savePassword">
        Confirm
      </div>
    </div>
  </div>

<div class="sm-container">
  <div class="pro-item">
    <ul>
      <li v-for="item in productList" :key="item.id" @click="details(item.id)">
        <img :src="item.cover">
        <div class="pro-item-txt">
          <h3>{{ item.title }}</h3>
          <p><span>No: {{ item.no }}</span></p>
        </div>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  name: "searchData",
  data(){
    return{
      productList:[],
      isShow:false,
      password:'',
    }
  },
  created() {
    // 判断是否有缓存
    const password = this.getCookie("password");
    if (password !== null && password !== undefined) {
      this.isShow = false;
      // 拿到数据
      this.keyword = this.$route.query.keyword;
       this.productList = JSON.parse(this.$route.query.result);

    } else {
      this.isShow = true;
    }
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods:{
    // 获取指定名称的cookie值
    getCookie(name) {
      const cookies = document.cookie.split("; ");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split("=");
        if (cookie[0] === name) {
          return cookie[1];
        }
      }
      return null;
    },
    // 设置cookie
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + value + expires + "; path=/";
    },
    //键盘enter
    handleKeyDown(event) {
      if (event.keyCode === 13 && document.activeElement === this.$refs.passwordInput) {
        this.savePassword();
      }
    },
    // 保存密码为cookie
    savePassword() {
      if (!this.password) {
        // 密码为空，进行相应的处理
        this.tips='Password is required'
        return;
      }
      this.setCookie("password", this.password, 1);
      console.log("Password:", this.password); // 打印密码
      this.isLoadingShow = true;
    },

  details(id){
    this.$router.push('/productDetails/' + id)
  },
}
}
</script>

<style scoped>
.sm-container{
  min-height: 800px;
}
.pro-item ul{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px 0;
}
.pro-item ul li{
  width:31%;
  list-style: none;
  margin-bottom: 40px;
  box-shadow: 1px 6px 10px #ededed;
  cursor: pointer;
}
.pro-item ul li img{
  max-height: 300px;
  display: block;
  margin: auto;
}
.pro-item ul:after {
  content: "";
  width:31%;
}
.pro-item-txt{
  padding:10px 10px 20px 10px;
}
.pro-item-txt h3{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.pro-item-txt p{
  padding-top: 10px;
}
.pro-item-txt p span{
  background: #b7b7b7;
  color: #fff;
  padding: 5px;
  display: inline-block;
  border-radius: 4px;
}

@media only screen and (min-width:0px) and (max-width:992px){
  .pro-item ul li{
    width:48%;
  }
  .pro-item ul:after {
    width:48%;
  }
}

</style>